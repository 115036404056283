<template>
  <div>
    <header-top-dashboard
      primary
      :custom-right-content="true"
      :show-back-button="true"
    >
    </header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n16">
      <v-row>
        <v-col class="mt-n3">
          <v-card class="card-shadow mb-6">
            <v-card-text class="card-padding">
              <v-form>
                <v-row>
                  <v-col cols="12" sm="7" class="py-0">
                    <h3>Survey Details</h3>
                  </v-col>
                  <v-col cols="12" sm="5" class="py-0">
                    <v-btn
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="mb-3 font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm ms-3"
                      color="#5ed4e4"
                      @click="downloadSurvey"
                      style="float: right;"
                    >
                      Download Survey
                    </v-btn>
                  </v-col>
                  <v-col md="12" class="py-0">
                    <v-divider style="margin-bottom: 15px"></v-divider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Survey Title</label
                    >
                    <v-text-field
                      :disabled="isSurveyDisabled"
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Survey Title"
                      v-model="survey.title"
                      required
                      :error-messages="
                        getFieldValidationErrors(v$.survey.title.$errors)
                      "
                      @blur="v$.survey.title.$touch"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Survey Description</label
                    >
                    <v-text-field
                      :disabled="isSurveyDisabled"
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Survey Description"
                      v-model="survey.description"
                      required
                      :error-messages="
                        getFieldValidationErrors(v$.survey.description.$errors)
                      "
                      @blur="v$.survey.description.$touch"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Survey Start Date</label
                    >

                    <v-menu
                      :disabled="isSurveyDisabled"
                      ref="surveyStartDateMenu"
                      v-model="surveyStartDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          dense
                          v-model="survey.startDate"
                          placeholder="Select start date..."
                          prepend-icon="ni ni-calendar-grid-58"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          class="input-style font-size-input text-light-input placeholder-light"
                          :error-messages="
                            getFieldValidationErrors(
                              v$.survey.startDate.$errors
                            )
                          "
                          @input="v$.survey.startDate.$touch"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="survey.startDate"
                        @input="surveyStartDateMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Survey End Date</label
                    >

                    <v-menu
                      :disabled="isSurveyDisabled"
                      ref="surveyEndDateMenu"
                      v-model="surveyEndDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          dense
                          v-model="survey.endDate"
                          placeholder="Select end date..."
                          prepend-icon="ni ni-calendar-grid-58"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          class="input-style font-size-input text-light-input placeholder-light"
                          :error-messages="
                            getFieldValidationErrors(v$.survey.endDate.$errors)
                          "
                          @input="v$.survey.endDate.$touch"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="survey.endDate"
                        @input="surveyEndDateMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Survey Status</label
                    >
                    <v-select
                      :disabled="isSurveyDisabled"
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Survey Status"
                      :items="surveyStatuses"
                      item-text="value"
                      item-value="key"
                      v-model="survey.status"
                      :error-messages="
                        getFieldValidationErrors(v$.survey.status.$errors)
                      "
                      @blur="v$.survey.status.$touch"
                    >
                    </v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <h3>Survey Questions</h3>
                    <v-divider style="margin-bottom: 15px"></v-divider>
                  </v-col>
                  <v-col cols="12" md="12" class="py-0">
                    <v-btn
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="mb-3 font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm ms-3"
                      color="#5e72e4"
                      @click="createNewSurveyQuestion"
                      ><v-icon class="me-3">ni-fat-add</v-icon>
                      Add New Question
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row
                  class="mb-1"
                  v-for="(item, index) in survey.questions"
                  :key="index"
                >
                  <v-col cols="10" class="py-0">
                    <v-expansion-panels>
                      <v-expansion-panel>
                        <v-expansion-panel-header>{{
                          item.question
                        }}</v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-card class="card-shadow mb-6">
                            <v-card-text
                              v-if="item.type == 'text'"
                              class="card-padding"
                            >
                              <p v-for="(answer, i) in item.answers" :key="i">
                                <v-icon>ni ni-bold-right</v-icon>
                                {{ answer.answer }}
                              </p>
                              <p v-if="item.answers == 0">
                                This question doesn't have answers yet.
                              </p>
                            </v-card-text>
                            <v-card-text v-else class="card-padding">
                              <p>
                                Total selections: {{ item.optionAnswersTotal }}
                              </p>
                              <p
                                v-for="(answer, i) in item.optionAnswers"
                                :key="i"
                              >
                                <v-icon>ni ni-bold-right</v-icon>
                                {{ answer.title }}: ({{ answer.count }})
                              </p>
                            </v-card-text>
                          </v-card>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>
                  <v-col cols="2" class="py-0">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          @click="editSurveyQuestion(item)"
                          elevation="0"
                          :ripple="false"
                          height="28"
                          min-width="36"
                          width="36"
                          class="font-weight-600 text-capitalize btn-ls btn-warning me-2 my-2 rounded-sm"
                          color="#40a0fb"
                        >
                          <v-icon size="12">ni-palette</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>

                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          @click="deleteItem(item.id)"
                          elevation="0"
                          :ripple="false"
                          height="28"
                          min-width="36"
                          width="36"
                          class="font-weight-600 text-capitalize btn-ls btn-danger me-2 my-2 rounded-sm"
                          color="#f5365c"
                        >
                          <v-icon size="12">ni-fat-remove</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </v-col>
                </v-row>

                <v-btn
                  @click="submit"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  :loading="editButton.isLoading"
                  class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm me-2"
                  style="margin-top: 30px"
                  color="#5e72e4"
                  >Submit</v-btn
                >
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapStores } from "pinia";
import { useSessionStore } from "@/store/session";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import HeaderTopDashboard from "@/components/HeaderTopDashboard.vue";
// import SimpleTable from "@/components/Tables/SimpleTable.vue";

export default {
  name: "surveys-edit",
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    HeaderTopDashboard,
    // SimpleTable,
  },
  validations() {
    return {
      survey: {
        title: { required },
        description: { required },
        audience: { required },
        startDate: { required },
        endDate: { required },
        status: { required },
      },
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      questionsTable: {
        headers: [
          {
            text: "#",
            align: "start",
            sortable: false,
            value: "position",
          },
          { text: "Question", value: "question" },
          { text: "# Answers", value: "numAnswers" },
          { text: "Status", value: "status" },
          { text: "Actions", value: "actions", sortable: false },
        ],
      },
      survey: {
        id: 0,
        title: "",
        description: "",
        audience: "",
        startDate: "",
        endDate: "",
        status: "",
        created_at: "",
        updated_at: "",
        numQuestions: "",
        questions: [],
      },
      surveyStartDateMenu: false,
      surveyEndDateMenu: false,
      editButton: {
        isLoading: false,
      },
    };
  },
  computed: {
    ...mapStores(useSessionStore),
    isSurveyDisabled() {
      return !["draft", "active"].includes(this.survey.status);
    },
  },
  methods: {
    createNewSurveyQuestion() {
      this.$router.push({
        name: "Create Survey Question",
        params: {
          surveyId: this.survey.id.toString(),
        },
      });
    },

    deleteItem(itemId) {
      this.$http
        .delete(
          `/surveys/${this.id}/questions/${itemId}`,
          this.sessionStore.getHttpConfig
        )
        .then(() => {
          this.fetchDataByUrl(`/surveys/${this.id}`);
          this.$notify.success("Question deleted successfully.");
        })
        .catch((error) => {
          console.error("Error: ", error);
          this.$notify.success(
            "An error occurred while deleting the survey question."
          );
        });
    },

    downloadSurvey() {
      const config = {
        responseType: "arraybuffer",
        ...this.sessionStore.getHttpConfig,
      };

      this.$http
        .get(`/surveys/${this.id}/download`, config)
        .then((response) => {
          const type = response.headers["content-type"];
          const blob = new Blob([response.data], {
            type: type,
            encoding: "UTF-8",
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "survey-results.xlsx";
          link.click();
          this.$notify.success("Survey downloaded to your download folder.");
        })
        .catch((error) => {
          console.error("Error: ", error);
          this.$notify.success(
            "An error occurred while download the survey excel."
          );
        });
    },

    editSurveyQuestion(item) {
      this.$router.push({
        name: "Edit Survey Question",
        params: {
          surveyId: this.survey.id.toString(),
          questionId: item.id.toString(),
        },
      });
    },

    fetchDataByUrl(url) {
      if (!url) {
        // TODO - print alert.
        return;
      }

      this.$http
        .get(url, this.sessionStore.getHttpConfig)
        .then((result) => {
          const survey = result.data.data[0];

          this.survey.id = survey.id;
          this.survey.title = survey.title;
          this.survey.description = survey.description;
          this.survey.audience = survey.audience;
          this.survey.startDate = survey.start_date;
          this.survey.endDate = survey.end_date;
          this.survey.status = survey.status;
          this.survey.created_at = survey.created_at;
          this.survey.updated_at = survey.updated_at;
          this.survey.numQuestions = survey.survey_questions.length;

          this.survey.questions.splice(0);
          for (let i = 0; i < survey.survey_questions.length; i++) {
            const surveyQuestion = survey.survey_questions[i];
            const question = {
              position: i + 1,
              id: surveyQuestion.id,
              question: surveyQuestion.question,
              numAnswers: surveyQuestion.survey_question_answers.length,
              status: surveyQuestion.status,
              answers: surveyQuestion.survey_question_answers,
              type: surveyQuestion.type,
              surveyQuestionOptions: surveyQuestion.survey_question_options,
              optionAnswers: {},
              optionAnswersTotal: 0,
            };

            if (question.type !== "text") {
              for (let k = 0; k < question.surveyQuestionOptions.length; k++) {
                question.optionAnswers[
                  question.surveyQuestionOptions[k].survey_question_id
                ] = {
                  title: question.surveyQuestionOptions[k].value,
                  count: 0,
                };
              }

              for (let k = 0; k < question.answers.length; k++) {
                question.optionAnswers[question.answers[k].survey_question_id]
                  .count++;
                question.optionAnswersTotal++;
              }
            }

            this.survey.questions.push(question);
          }
        })
        .catch((error) => {
          this.$notify.error(
            "An error occurred while loading the delivery details. Please try again later or contact support."
          );
          console.error("Error while fetching delivery details: ", error);
        });
    },

    getFieldValidationErrors(errors) {
      const messages = [];

      for (let i = 0; i < errors.length; i++) {
        messages.push(`${errors[i].$message}`);
      }

      return messages;
    },

    async submit() {
      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) {
        this.$notify.warning(
          "Please resolve the validation errors before proceeding!"
        );
        return;
      }

      this.submitButton.isLoading = true;

      const surveyBody = {
        title: this.survey.title,
        description: this.survey.description,
        audience: this.survey.audience,
        start_date: this.survey.startDate,
        end_date: this.survey.endDate,
        status: this.survey.status,
        created_at: this.survey.created_at,
        updated_at: this.survey.updated_at,
      };

      this.$http
        .patch(
          `/surveys/${this.id}`,
          surveyBody,
          this.sessionStore.getHttpConfig
        )
        .then(async (result) => {
          this.$notify.success("Survey updated successfully!");
          this.$router.push({
            name: "Edit Survey",
            params: {
              id: result.data.data[0].id.toString(),
            },
          });
        })
        .catch((error) => {
          this.$notify.error(
            "An error occurred while updating the survey. Please try again later or contact support."
          );
          console.error("Error while updating survey: ", error);
        })
        .finally(() => {
          this.submitButton.isLoading = false;
        });
    },

    updateSurveyQuestionStatus(item, status) {
      const body = {
        status,
      };

      this.$http
        .patch(
          `/surveys/${this.id}/questions/${item.id}`,
          body,
          this.sessionStore.getHttpConfig
        )
        .then(() => {
          this.fetchDataByUrl(`/surveys/${this.id}`);
          this.$notify.success("Status updated successfully.");
        })
        .catch((error) => {
          console.error("Error: ", error);
          this.$notify.success("An error occurred while updating the status.");
        });
    },
  },
  mounted() {
    this.fetchDataByUrl(`/surveys/${this.id}`);
  },
};
</script>
